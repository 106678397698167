module services {
    export module master {
        export class warehouseService implements interfaces.master.IWarehouseService {
            static $inject = ["$resource", "ENV","$http", "$timeout","generalService"];

            constructor(private $resource: ng.resource.IResourceService, private ENV: interfaces.applicationcore.serverConfig,private $http: ng.IHttpService,private $timeout: ng.ITimeoutService,public generalService: interfaces.applicationcore.IGeneralService) {
            }

            //Gets list of all Terms Of Payment for a dropdown/autocomplete list
            getDropdownList(): ng.resource.IResourceClass<interfaces.applicationcore.IDropdownModel> {

                return this.$resource<interfaces.applicationcore.IDropdownModel>(this.ENV.DSP_URL + "Warehouse/GetDropdownList", {
                    ownerEntityId: '@ownerEntityId',
                    unLocationCodeId: '@unLocationCodeId',
                    CountryId: '@CountryId',
                    Customs: '@Customs',
                    managedWarehouse: '@managedWarehouse'
                });
            }

            getCustomsWarehouses(managedWarehouse?: boolean): ng.resource.IResourceClass<interfaces.applicationcore.IDropdownModel> {
                return this.$resource<interfaces.applicationcore.IDropdownModel>(this.ENV.DSP_URL + "Warehouse/GetCustomsWarehouses", {
                    managedWarehouse: managedWarehouse
                });
            }

            getBondStoreReceiptsViewModel(): ng.resource.IResourceClass<interfaces.master.BondStoreReceiptsViewModel> {
                return this.$resource<interfaces.master.BondStoreReceiptsViewModel>(this.ENV.DSP_URL + "Warehouse/GetBondStoreReceiptsViewModel", {
                    connectSearch: '@connectSearch',
                    ToWarehouseId: '@ToWarehouseId',
                    showAll : '@showAll',
                    showAgedStock: '@showAgedStock',
                    numberRecords: '@numberRecords',
                    pageNumber: '@pageNumber',
                }, {
                        query: {
                            method: 'GET',
                            isArray: true,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates },
                            params: {
                                numberRecords: 25,
                                pageNumber: 1,
                            }
                        }
                    });
            }     


            getBondStoreDisposalsViewModel(): ng.resource.IResourceClass<interfaces.master.BondStoreDisposalsViewModel> {
                return this.$resource<interfaces.master.BondStoreDisposalsViewModel>(this.ENV.DSP_URL + "Warehouse/GetBondStoreDisposalsViewModel", {
                    connectSearch: '@connectSearch',
                    GoodsDeclarationLineId: '@GoodsDeclarationLineId',
                    numberRecords: '@numberRecords',
                    pageNumber: '@pageNumber',
                }, {
                        query: {
                            method: 'GET',
                            isArray: true,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates },
                            params: {
                                numberRecords: 25,
                                pageNumber: 1,
                            }
                        }
                    });
            }
            
            getBondStoreRegisterExcel(params: any, ToWarehouseId:number, showAll:boolean, showAgedStock: boolean): ng.IPromise<void> {
                var pdfDownload = document.createElement("a");
                document.body.appendChild(pdfDownload);

                var url = this.ENV.DSP_URL + 'Warehouse/GetBondStoreRegisterExcel?';

                var searchObject: interfaces.applicationcore.ISearchObject = {
                    filters: [],
                    sorts: []
                }
                searchObject = params.connectSearch;

               
                url += 'ToWarehouseId='+ToWarehouseId;
                url += '&showAll='+showAll;
                url += '&showAgedStock='+showAgedStock;
                url += '&connectSearch=' + btoa(JSON.stringify(searchObject));
                return this.$http({
                    method: 'GET',
                    responseType: 'arraybuffer',
                    url: url,
                }).then((response) => {
                    var filename = response.headers()['x-filename'] || 'supplierDownload.xlsx';
                    var fileBlob = new Blob([response.data as BlobPart], { type: 'application/octet-stream' });

                    if (navigator.appVersion.toString().indexOf('.NET') > 0) { // for IE browser
                        window.navigator.msSaveBlob(fileBlob, filename);
                    } else { // for other browsers
                        var fileURL = window.URL.createObjectURL(fileBlob);
                        pdfDownload.href = fileURL;
                        pdfDownload.download = filename;
                        pdfDownload.click();
                        pdfDownload.remove();
                    }

                    this.$timeout(() => {
                        this.generalService.displayMessage("Your download has started.", Enum.EnumMessageType.Success);
                    });
                }).catch((errorResponse) => {
                    var fileBlob = new Blob([errorResponse.data], { type: 'application/json' });
                    var fileURL = window.URL.createObjectURL(fileBlob);
                    const reader = new FileReader();

                    // This fires after the blob has been read/loaded.
                    reader.addEventListener('loadend', (e: ProgressEvent) => {
                        const text = e.target['result'];

                        this.$timeout(() => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>JSON.parse(text));
                        });
                    });

                    // Start reading the blob as text.
                    reader.readAsText(fileBlob);
                });
            }    

        }
    }
    angular.module("app").service("warehouseService", services.master.warehouseService);
}